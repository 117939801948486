<template>
  <modal-dialog-trigger
    v-on:action="handle"
    :primary-action-label="confirmLabel"
    :secondary-action-label="cancelLabel"
    :title="title"
    close-type="secondary"
    confirm-link="#"
    :loading="loading"
  >
    <a slot="trigger"><i :class="icon"></i></a>
    <slot name="content" slot="content"/>
  </modal-dialog-trigger>
</template>

<script>
import ModalDialogTrigger from '../components/ModalDialogTrigger'
import { post } from '../util/request'

export default {
  props: {
    token: String,
    title: String,
    confirmLabel: String,
    cancelLabel: String,
    url: String,
    icon: String
  },
  components: { ModalDialogTrigger },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    async handle () {
      this.loading = true
      try {
        const { data } = await post(this.url, { _token: this.token })
        document.location = data.url
      } catch (error) {
        console.warn(error)
        this.loading = false
      }
    }
  }
}
</script>
