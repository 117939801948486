<template>
  <div class="modal modal--active">
    <div class="modal__background" @click="close()"></div>
    <div class="modal__content" ref="modalContent" :class="{'modal__content--loading': loading}">
      <a v-if="closeButtonInside && showCloseButton" class="close-button close-button--modal" :style="closeButtonStyles" @click="close()"></a>
      <slot :close="close"></slot>
    </div>
    <button v-if="!closeButtonInside && showCloseButton" class="close-button close-button--modal close-button--dark" @click="close()"></button>
  </div>
</template>

<script>
import disableBodyScroll from '../../util/disable-body-scroll'
import { enableBodyScroll } from 'body-scroll-lock';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      default: true,
      type: Boolean
    },
    closeButtonInside: {
      type: Boolean,
      default: false
    },
    closeButtonPosition: Number //from left, so close buttons can be positioned next to the scrollbar
  },
  mounted() {
    document.addEventListener('keydown', this.detectEscapeKey, true);
    this.$nextTick().then(() => {
      disableBodyScroll(this.$refs.modalContent)
    })
  },
  beforeDestroy() {
    enableBodyScroll(this.$refs.modalContent)
    document.removeEventListener('keydown', this.detectEscapeKey, true)
    this.close()
  },
  computed: {
    closeButtonStyles() {
      if (!this.closeButtonPosition) {
        return {}
      }
      return {
        left: this.closeButtonPosition + 'px',
        transform: 'translate(-100%, 0)'
      }
    }
  },
  methods: {
    detectEscapeKey (e) {
      if (e.keyCode === 27) this.close()
    },
    close () {
      this.$emit('close')
    },
  }
}
</script>
