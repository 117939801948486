'use strict'

import { disableBodyScroll } from 'body-scroll-lock'

export default (element) => {
  disableBodyScroll(element, {
    allowTouchMove: el => {
      while (el && el !== document.body) {
        //allow scrolling in html elements with the attribute body-scroll-lock-ignore
        if (el.getAttribute('body-scroll-lock-ignore') !== null || el.classList.contains('js-body-scroll-lock-ignore')) {
          return true
        }

        el = el.parentNode
      }
    },
  });
}
