var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal modal--active" }, [
    _c("div", {
      staticClass: "modal__background",
      on: {
        click: function ($event) {
          return _vm.close()
        },
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "modalContent",
        staticClass: "modal__content",
        class: { "modal__content--loading": _vm.loading },
      },
      [
        _vm.closeButtonInside && _vm.showCloseButton
          ? _c("a", {
              staticClass: "close-button close-button--modal",
              style: _vm.closeButtonStyles,
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default", null, { close: _vm.close }),
      ],
      2
    ),
    _vm._v(" "),
    !_vm.closeButtonInside && _vm.showCloseButton
      ? _c("button", {
          staticClass: "close-button close-button--modal close-button--dark",
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }