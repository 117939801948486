var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.error ? _c("warning", { attrs: { message: _vm.error } }) : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-3" }, [
        _c("label", { staticClass: "form-group__label" }, [_vm._v("Titel")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title",
            },
          ],
          staticClass: "form-group__field",
          attrs: { type: "text" },
          domProps: { value: _vm.title },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.title = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-3" }, [
        _c("label", { staticClass: "form-group__label" }, [_vm._v("Stream")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.streamCode,
                expression: "streamCode",
              },
            ],
            staticClass: "form-group__field",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.streamCode = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c("option", { attrs: { disabled: "" } }, [
              _vm._v("Kies een stream"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.streamCodes, function (streamCode) {
              return _c("option", { domProps: { value: streamCode } }, [
                _vm._v(_vm._s(streamCode)),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "table table--full-width table--condensed mb-3" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.demoSetPairs, function (demoSetPair) {
                return _c("tr", [
                  _c("td", [_vm._v(_vm._s(demoSetPair.sourceUser.fullName))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(demoSetPair.targetUser.fullName))]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.removePair(demoSetPair)
                          },
                        },
                      },
                      [_c("i", { staticClass: "icon-trash" })]
                    ),
                  ]),
                ])
              }),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newPair.sourceUser,
                          expression: "newPair.sourceUser",
                        },
                      ],
                      staticClass: "form-group__field",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.newPair,
                              "sourceUser",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.addPair,
                        ],
                      },
                    },
                    [
                      _c(
                        "option",
                        { attrs: { disabled: "" }, domProps: { value: null } },
                        [_vm._v("Kies een gebruiker")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.sourceUsers, function (sourceUser) {
                        return _c(
                          "option",
                          {
                            key: sourceUser.userReference,
                            domProps: { value: sourceUser },
                          },
                          [_vm._v(_vm._s(sourceUser.fullName))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newPair.targetUser,
                          expression: "newPair.targetUser",
                        },
                      ],
                      staticClass: "form-group__field",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.newPair,
                              "targetUser",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.addPair,
                        ],
                      },
                    },
                    [
                      _c(
                        "option",
                        { attrs: { disabled: "" }, domProps: { value: null } },
                        [_vm._v("Kies een gebruiker")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.availableTargetUsers, function (targetUser) {
                        return _c(
                          "option",
                          {
                            key: targetUser.userReference,
                            domProps: { value: targetUser },
                          },
                          [_vm._v(_vm._s(targetUser.fullName))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("td"),
              ]),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "button button--primary",
          attrs: { disabled: _vm.loading },
          on: { click: _vm.save },
        },
        [_c("i", { staticClass: "icon-save" }), _vm._v(" Opslaan")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Template")]),
        _vm._v(" "),
        _c("th", [_vm._v("Demonstratie")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }