<template>
  <modal-dialog-trigger
    v-on:action="deleteMaintenanceAnnouncement"
    :primary-action-label="confirmLabel"
    :secondary-action-label="cancelLabel"
    :title="title"
    close-type="secondary"
    confirm-link="#"
    container-class="flexbox flex"
    trigger-class="flexbox flex"
    :loading="loading"
  >
    <a slot="trigger"><i class="icon-trash"></i></a>
    <slot name="content" slot="content"/>
  </modal-dialog-trigger>
</template>

<script>
  import ModalDialogTrigger from '../components/ModalDialogTrigger'
  import Routing from '../util/routing'
  import { post } from '../util/request'

  export default {
    props: {
      token: String,
      announcementId: Number,
      title: String,
      confirmLabel: String,
      cancelLabel: String
    },
    components: { ModalDialogTrigger },
    data () {
      return {
        loading: false,
      }
    },
    methods: {
      async deleteMaintenanceAnnouncement () {
        this.loading = true
        try {
          const url = Routing.generate('admin_maintenance_delete', {
            announcementId: this.announcementId
          })
          await post(url, { _token: this.token })

          document.location.reload()
        } catch (error) {
          console.warn(error)
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
