export default class Collapse {
  constructor (button) {
    const updateButtonClass = () => {
      const targetIsCollapsed = document.getElementById(button.dataset.collapseId).classList.contains('is-collapsed')
      button.classList.toggle('target-is-collapsed', targetIsCollapsed)
    }
    updateButtonClass()

    button.addEventListener('click', (evt) => {
      evt.preventDefault()
      document.getElementById(button.dataset.collapseId).classList.toggle('is-collapsed')
      updateButtonClass()
    })
  }
}
