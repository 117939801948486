export default class Notification {
  constructor (message, type, icon, label) {
    this.message = message
    this.type = type
    this.icon = icon
    this.label = label
  }

  static warning(message) {
    return new Notification(message, 'warning', 'icon-alert', '');
  }

  static success(message) {
    return new Notification(message, 'success', 'icon-info', '');
  }

  static lock(message) {
    return new Notification(message, 'lock', 'icon-lock icon--grey', '');
  }
}
