import request from '../util/request'

export default class RepairButton {
  constructor (element) {
    element.addEventListener('click', (event) => {
      event.preventDefault()
      element.classList.toggle('button--loading', true)
      request(element.href).then((response) => {
        element.classList.toggle('button--loading', false)
        element.classList.toggle('button--disabled', true)
      })
    })
  }
}
