<template>
  <div
    class="button button--primary button--tiny button--hoverable-icon-left"
    :class="loading ? 'button--disabled' : ''"
    @click="onClick"
  >
    <i :class="[icon, 'mr-1']"></i> {{ title }}
  </div>
</template>

<script>
import request from '../util/request'

export default {
  props: {
    title: String,
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'icon-view'
    },
    data: {
      type: String,
      default: ''
    },
    warning: {
      type: String,
      default: ''
    },
    target: String
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    onClick () {
      if (this.warning.length > 0) {
        if (confirm(this.warning)) {
          this.showData()
        }
      } else {
        this.showData()
      }
    },
    showData () {
      if (this.data.length > 0) {
        this.setContainerValue(this.data)
      } else if (this.url.length > 0) {
        this.setContainerValue('loading "' + this.url + '" ...')
        this.loadAndShow()
      } else {
        this.setContainerValue('?')
      }
    },
    setContainerValue(value) {
      document.querySelector(this.target).innerHTML = value
    },
    async loadAndShow () {
      this.loading = true
      try {
        const { data } = await request(this.url)
        this.setContainerValue(JSON.stringify(data, null, 4))
      } catch (error) {
        console.warn(error)
        this.setContainerValue(JSON.stringify(error, null, 4))
      }
      this.loading = false
    }
  }
}
</script>
