<template>
  <div>
    <warning v-if="error" :message="error"/>

    <div class="form-group mb-3">
      <label class="form-group__label">Titel</label>
      <input type="text" class="form-group__field" v-model="title" />
    </div>
    <div class="form-group mb-3">
      <label class="form-group__label">Stream</label>
      <select class="form-group__field" v-model="streamCode">
        <option disabled>Kies een stream</option>
        <option v-for="streamCode in streamCodes" :value="streamCode">{{ streamCode }}</option>
      </select>
    </div>
    <table class="table table--full-width table--condensed mb-3">
      <thead>
        <tr>
          <th>Template</th>
          <th>Demonstratie</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="demoSetPair in demoSetPairs">
          <td>{{ demoSetPair.sourceUser.fullName }}</td>
          <td>{{ demoSetPair.targetUser.fullName }}</td>
          <td><a @click="removePair(demoSetPair)"><i class="icon-trash"></i></a></td>
        </tr>
        <tr>
          <td>
            <select class="form-group__field" v-model="newPair.sourceUser" @change="addPair">
              <option disabled :value="null">Kies een gebruiker</option>
              <option v-for="sourceUser in sourceUsers" :key="sourceUser.userReference" :value="sourceUser">{{ sourceUser.fullName }}</option>
            </select>
          </td>
          <td>
            <select class="form-group__field" v-model="newPair.targetUser" @change="addPair">
              <option disabled :value="null">Kies een gebruiker</option>
              <option v-for="targetUser in availableTargetUsers" :key="targetUser.userReference" :value="targetUser">{{ targetUser.fullName }}</option>
            </select>
          </td>
          <td>

          </td>
        </tr>
      </tbody>
    </table>

    <a class="button button--primary" :disabled="loading" @click="save"><i class="icon-save"></i> Opslaan</a>
  </div>
</template>

<script>
import { post } from '../util/request'
import Warning from '../components/notification/Warning'
import {captureException} from "@sentry/vue";

export default {
  components: {
    Warning,
  },
  props: {
    sourceUsers: Array,
    targetUsers: Array,
    streamCodes: Array,

    saveUrl: String,
    listUrl: String,
    userDemoSet: Object,
    token: String,
  },
  data () {
    const demoSetPairs = [];
    const availableTargetUsers = this.targetUsers.slice();
    if (this.userDemoSet) {
      for (const pair of this.userDemoSet.demoSetPairs) {
        const sourceUser = this.sourceUsers.find((user) => user.userReference === pair.sourceUserReference)
        const targetUser = this.targetUsers.find((user) => user.userReference === pair.targetUserReference)
        if (sourceUser !== undefined && targetUser !== undefined) {
          availableTargetUsers.splice(availableTargetUsers.indexOf(targetUser), 1)
          demoSetPairs.push({ sourceUser, targetUser })
        }
        //todo show message if one of them is not found, possibly removed from the group
      }
    }
    return {
      title: this.userDemoSet ? this.userDemoSet.title : '',
      streamCode: this.userDemoSet ? this.userDemoSet.streamCode : '',
      demoSetPairs: demoSetPairs,

      availableTargetUsers: availableTargetUsers,

      newPair: {
        sourceUser: null,
        targetUser: null
      },

      loading: false,
      error: null
    }
  },
  computed: {
    valid () {
      return this.titleIsValid &&
        this.streamCodeIsValid &&
        this.usersAreValid
    },
    titleIsValid () {
      return this.title !== ''
    },
    streamCodeIsValid () {
      return this.streamCode !== ''
    },
    usersAreValid() {
      return this.demoSetPairs.length > 0
    }
  },
  methods: {
    addPair() {
      if (this.newPair.sourceUser !== null && this.newPair.targetUser !== null) {

        this.demoSetPairs.push({
          sourceUser: this.newPair.sourceUser,
          targetUser: this.newPair.targetUser,
        })
        this.availableTargetUsers.splice(this.availableTargetUsers.indexOf(this.newPair.targetUser), 1)
        this.newPair.sourceUser = null
        this.newPair.targetUser = null
      }
    },
    removePair(demoSetPair) {
      const index = this.demoSetPairs.indexOf(demoSetPair)
      this.demoSetPairs.splice(index, 1)
      this.availableTargetUsers.push(demoSetPair.targetUser)
    },
    async save () {
      if (this.loading) {
        return
      }
      if (!this.valid) {
        const errors = [];
        if (!this.titleIsValid) {
          errors.push('Vul een titel in')
        }
        if (!this.streamCodeIsValid) {
          errors.push('Kies een stream')
        }
        if (!this.usersAreValid) {
          errors.push('Kies tenminste één set aan gebruikers')
        }
        this.error = errors.join('<br />')
        return
      }
      this.error = null
      this.loading = true
      try {
        await post(this.saveUrl, {
          title: this.title,
          streamCode: this.streamCode,
          demoSetPairs: this.demoSetPairs.map((pair) => {
            return {
              sourceUserReference: pair.sourceUser.userReference,
              targetUserReference: pair.targetUser.userReference,
            }
          }),
          _token: this.token
        })
        document.location = this.listUrl
      } catch (exception) {
        captureException(new Error(exception.message))
        this.error = exception.message
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
