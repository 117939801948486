'use strict';

export default (container, elementsOrSelector, className, options) => {
  if (typeof elementsOrSelector === 'string') {
    elementsOrSelector = container.querySelectorAll(elementsOrSelector)
  }

  elementsOrSelector.forEach(element => {
    //already bound
    if (element.bound === className.name) {
      return
    }
    new className(element, options)
    element.bound = className.name
  })
}
