import request from '../util/request'

export default class DeleteWithJsConfirmButton {
  constructor (element) {
    element.addEventListener('click', (event) => {
      event.preventDefault()
      if (confirm(this.getConfirmMessage(element))) {
        element.classList.toggle('button--loading', true)
        request(element.href).then((response) => {
          element.classList.toggle('button--loading', false)
          element.classList.toggle('button--disabled', true)
        })
      }
    })
  }

  getConfirmMessage (element) {
    if ('confirmMessage' in element.dataset) {
      return element.dataset.confirmMessage
    }
    return 'Weet u het zeker?'
  }
}
