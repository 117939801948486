<template>
  <modal :loading="loading" :close-button-inside="true" @close="close" :show-close-button="showCloseButton" :class="{ 'modal--fullscreen': fullscreen }">
    <div class="confirm-modal__content">
      <h1 class="title" v-html="title"></h1>
      <slot name="content"></slot>
      <div class="flexbox flex-align-items--center"
           :class="{ 'flex-justify-content--end': !secondaryActionLabel }"
      >

        <a v-on:click="primaryAction" v-if="$slots['primary-action']" id="modal-confirm-primary-action" class="confirm-modal__primary-action" :href="primaryHref">
          <slot name="primary-action" />
        </a>
        <a v-on:click="primaryAction"
           v-else
           :href="primaryHref"
           :id="'modal-confirm-primary-action'"
           class="button button--primary"
           :class="{
                    'button--disabled': primaryDisabled,
                    'confirm-modal__primary-action': secondaryActionLabel
                   }">{{ primaryActionLabel }}</a>

        <a v-on:click="secondaryAction" v-if="$slots['secondary-action']" :href="secondaryHref" class="confirm-modal__secondary-action" :class="{ 'disabled': secondaryDisabled }">
          <slot name="secondary-action" />
        </a>

        <a v-on:click="secondaryAction"
           v-else
           :href="secondaryHref"
           class="confirm-modal__secondary-action"
           :class="{ 'disabled': secondaryDisabled }">{{ secondaryActionLabel }}</a>
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from './Modal'

  export default {
    components: {
      Modal
    },
    props: {
      title: String,
      closeType: String,
      confirmLink: String,
      primaryActionLabel: String,
      secondaryActionLabel: String,
      actionDisabled: Boolean,
      loading: Boolean,

      showCloseButton: {
        default: false,
        type: Boolean
      },
      closeable: {
        default: true,
        type: Boolean
      },
      fullscreen: {
        default: false,
        type: Boolean
      },
    },

    mounted: function () {
      document.body.appendChild(this.$el)
      document.addEventListener('keydown', this.detectEscapeKey, true)
    },

    beforeDestroy: function () {
      if (this.$el.parentNode === document.body) {
        document.body.removeChild(this.$el)
      }
    },

    computed: {
      primaryHref: function () {
        if (this.closeType === 'secondary' && this.confirmLink) {
          return this.confirmLink
        }
        return false
      },
      secondaryHref: function () {
        if (this.closeType === 'primary' && this.confirmLink) {
          return this.confirmLink
        }
        return false
      },
      primaryDisabled: function () {
        return this.closeType === 'secondary' && this.actionDisabled
      },
      secondaryDisabled: function () {
        return this.closeType === 'primary' && this.actionDisabled
      },
    },

    methods: {
      detectEscapeKey (e) {
        if (e.keyCode === 27) this.close()
      },

      close () {
        if (this.closeable) this.$emit('close')
      },

      primaryAction () {
        if (this.primaryDisabled) {
          return
        }
        if (this.closeType === 'primary') {
          this.close()
          return
        }
        this.$emit('action')
      },

      secondaryAction () {
        if (this.secondaryDisabled) {
          return
        }
        if (this.closeType === 'secondary') {
          this.close()
          return
        }
        this.$emit('action')
      }
    }
  }
</script>
