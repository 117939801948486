var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog-trigger",
    {
      attrs: {
        "primary-action-label": _vm.confirmLabel,
        "secondary-action-label": _vm.cancelLabel,
        title: _vm.title,
        "close-type": "secondary",
        "confirm-link": "#",
        "container-class": "flexbox flex",
        "trigger-class": "flexbox flex",
        loading: _vm.loading,
      },
      on: { action: _vm.deleteMaintenanceAnnouncement },
    },
    [
      _c("a", { attrs: { slot: "trigger" }, slot: "trigger" }, [
        _c("i", { staticClass: "icon-trash" }),
      ]),
      _vm._v(" "),
      _vm._t("content", null, { slot: "content" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }