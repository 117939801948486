<template>
  <div class="notification" :class="'notification--' + notification.type" role="alert">
    <div class="notification__label">
      <i :class="notification.icon"></i>
    </div>
    <div class="notification__text" v-html="notification.message"></div>
  </div>
</template>

<script>
  import Notification from './Notification'
  export default {
    props: {
      notification: {
        type: Notification
      },
    }
  }
</script>
