import '@babel/polyfill';
import 'array-flat-polyfill';
import '../../style/style.scss';

// Web components (https://github.com/Thiememeulenhoff/frontend)
import '@thiememeulenhoff/albert';
// End web components

import Vue from 'vue';
import Collapse from './components/Collapse';
import DataButton from './components/DataButton';
import DeleteWithJsConfirmButton from './components/DeleteWithJsConfirmButton';
import RepairButton from './components/RepairButton';
import DeleteMaintenanceAnnouncement from './maintenance/DeleteMaintenanceAnnouncement';
import MaintenanceAnnouncementForm from './maintenance/MaintenanceAnnouncementForm';
import bindElements from './util/bind-elements';

import * as Sentry from "@sentry/vue";
import UserDemoSetActionButton from './user-data-sync/UserDemoSetActionButton';
import UserDemoSetForm from './user-data-sync/UserDemoSetForm';

if (window.sentryConf !== undefined) {
  Sentry.init({
    Vue,
    dsn: "https://6a5dbb6e66d549cf883959b093670f3a@o1127103.ingest.sentry.io/4504791452155904",
    environment: window.sentryConf.environment,
    trackComponents: true,
  });
}

require('./util/polyfills.js')

document.addEventListener('DOMContentLoaded', function () {
  setupElements(document)
})

export function setupElements (elem) {
  bindElements(elem, '.js-collapse', Collapse)
  bindElements(elem, '.js-repair-button', RepairButton)
  bindElements(elem, '.js-delete-with-js-confirm-button', DeleteWithJsConfirmButton)
  bindElements(elem, '.js-maintenance-announcement-form', MaintenanceAnnouncementForm)
}

new Vue({
  el: document.getElementById('admin-app'),

  components: {
    DeleteMaintenanceAnnouncement,
    UserDemoSetForm,
    UserDemoSetActionButton,
    DataButton
  }
})
