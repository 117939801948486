export default class MaintenanceAnnouncementForm {
  constructor (element) {
    this.form = element
    element.addEventListener('change', event => {
      if ('form_method' === event.target.id) {
        this.selectMethod(event.target)
      }
    })

    this.selectMethod(document.getElementById('form_method'))
  }

  selectMethod(element) {
    const streamCodes = this.getStreamCodes(element)
    const options = document.querySelectorAll('#form_streamCode option')
    options.forEach(option => {
      const visible = null === streamCodes || option.value === '' || streamCodes.indexOf(option.value) >= 0
      option.style.display = visible ? 'block' : 'none'

      if (!visible && option.selected) {
        document.getElementById('form_streamCode').selectedIndex = 0
      }
    })
  }

  getStreamCodes(element) {
    try {
      return element.options[element.selectedIndex].dataset.streamCodes.split(',')
    } catch(e) {
    }
    return null
  }
}
