'use strict'

import axios from 'axios'
import Routing from './routing'

const jsonHeaders = {
  'X-Requested-With': 'XMLHttpRequest',
  'Accept': 'application/json'
}

export default function request (url, params) {
  return axios.get(url, { params: params, headers: jsonHeaders })
}

export function requestHtml (url, params) {
  return axios.get(url, { params: params, headers: { 'X-Requested-With': 'XMLHttpRequest' } })
}

export function post (url, data) {
  return axios.post(url, data, { headers: jsonHeaders })
}

export function postForm (url, data) {
  return axios.post(url, data, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function routePost (route, routeParams, data) {
  return post(Routing.generate(route, routeParams), data)
}

export function routeGet (route, routeParams) {
  return request(Routing.generate(route, routeParams))
}

export function deleteRequest (url, params) {
  return axios.delete(url, { params: params, headers: jsonHeaders })
}