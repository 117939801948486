  <template>
    <component :is="tag" :class="containerClass">
        <span v-on:click="triggerClicked()" :class="triggerClass"><slot name="trigger"></slot></span>
        <modal-dialog
          v-if="opened"
          :fullscreen="fullscreen"
          v-on:close="close"
          v-on:action="action"
          :closeable="closeable"
          :show-close-button="showCloseButton"
          :title="title"
          :close-type="closeType"
          :confirm-link="confirmLink"
          :primary-action-label="primaryActionLabel"
          :secondary-action-label="secondaryActionLabel"
          :action-disabled="actionDisabled"
          :loading="loading"
        >
          <slot name="content" slot="content"/>

          <template slot="primary-action">
            <slot name="primary-action" />
          </template>

          <template slot="secondary-action">
            <slot name="secondary-action" />
          </template>
        </modal-dialog>
    </component>
</template>

<script>
  import ModalDialog from './modal/ModalDialog.vue'

  export default {
    components: {
      ModalDialog
    },

    data: function () {
      return {
        opened: false
      }
    },

    mounted () {
      if (this.openOnPageLoad) {
        this.opened = true
      }
    },

    watch: {
      opened (state) {
        if (state) {
          document.body.classList.add('modal-opened')
        } else {
          document.body.classList.remove('modal-opened')
        }
      }
    },

    props: {
      title: String,
      closeType: String,
      confirmLink: {
        type: String,
        default: '#'
      },
      primaryActionLabel: String,
      secondaryActionLabel: String,
      actionDisabled: Boolean,
      triggerDisabled: Boolean,
      loading: Boolean,
      containerClass: String,
      triggerClass: String,

      tag: {
        default: 'span',
        type: String
      },
      showCloseButton: {
        default: false,
        type: Boolean
      },
      closeable: {
        default: true,
        type: Boolean
      },
      fullscreen: {
        default: false,
        type: Boolean
      },
      openOnPageLoad: {
        default: false,
        type: Boolean
      },
    },

    methods: {
      triggerClicked () {
        if (this.triggerDisabled) {
          return
        }
        this.opened = true
        this.$emit('open')
      },
      action () {
        this.$emit('action')
      },
      close () {
        this.opened = false
        this.$emit('close')
      },

      primaryAction () {
        if (this.closeType === 'primary') {
          this.close()
        }
      },
      secondaryAction () {
        if (this.closeType === 'secondary') {
          this.close()
        }
      }
    }
  }
</script>
