var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "button button--primary button--tiny button--hoverable-icon-left",
      class: _vm.loading ? "button--disabled" : "",
      on: { click: _vm.onClick },
    },
    [
      _c("i", { class: [_vm.icon, "mr-1"] }),
      _vm._v(" " + _vm._s(_vm.title) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }