var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    { tag: "component", class: _vm.containerClass },
    [
      _c(
        "span",
        {
          class: _vm.triggerClass,
          on: {
            click: function ($event) {
              return _vm.triggerClicked()
            },
          },
        },
        [_vm._t("trigger")],
        2
      ),
      _vm._v(" "),
      _vm.opened
        ? _c(
            "modal-dialog",
            {
              attrs: {
                fullscreen: _vm.fullscreen,
                closeable: _vm.closeable,
                "show-close-button": _vm.showCloseButton,
                title: _vm.title,
                "close-type": _vm.closeType,
                "confirm-link": _vm.confirmLink,
                "primary-action-label": _vm.primaryActionLabel,
                "secondary-action-label": _vm.secondaryActionLabel,
                "action-disabled": _vm.actionDisabled,
                loading: _vm.loading,
              },
              on: { close: _vm.close, action: _vm.action },
            },
            [
              _vm._t("content", null, { slot: "content" }),
              _vm._v(" "),
              _c(
                "template",
                { slot: "primary-action" },
                [_vm._t("primary-action")],
                2
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "secondary-action" },
                [_vm._t("secondary-action")],
                2
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }